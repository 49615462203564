import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
export default {
  props: {
    announcements: {
      required: true,
      type: Map
    },
    messages: {
      required: true,
      type: Array
    }
  }
};